* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    /* font-size: 62.5%; */
    min-width: 350px;
    min-height: 100vh;
}
.App {
    color: white;
    background-color: black;
}
