.name {
    font-size: 4rem;
    color: white;
}
.titles div {
    display: flex;
    // margin: 3px;
}
.username {
    font-size: 3rem;
    color: orange;
    border-radius: 10px;
    border: 2px solid white;
    padding: 0 5px 5px;
    margin-top: 3px;
    margin-left: 10px;
    // display: inline-block;
    // position: relative;
    // top: 5px;
}
.profession {
    font-size: 3rem;
    color: blueviolet;
}
.tech-stack {
    font-size: 2rem;
    color: darkgray;
}

// DESCKTOP
// .titles div {
//     display: flex;
// }
// .username {
//     font-size: 3rem;
//     color: orange;
//     display: inline-block;
//     position: relative;
//     top: 5px;
// }